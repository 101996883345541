import { useEffect, useState, useMemo } from "react";

import { useRouter } from 'next/router'

import { LocaleProvider as SemiLocaleProvider } from '@douyinfe/semi-ui';
import zh_CN from '@douyinfe/semi-ui/lib/es/locale/source/zh_CN';
import en_US from '@douyinfe/semi-ui/lib/es/locale/source/en_US';

import { IntlProvider } from 'react-intl'

import ENLang from 'langs/compiled/en-US.json'
import ZHLang from 'langs/compiled/zh-CN.json'

export default function LocaleProvider({ children }: React.PropsWithChildren<unknown>): JSX.Element {
    const { locale } = useRouter()

    const messages = useMemo(() => {
        switch (locale) {
            case "en-US":
                return ENLang;
            case "zh-CN":
                return ZHLang;
            default:
                return ENLang;
        }
    }, [locale]);

    const nextLocale = useMemo(() => {
        switch (locale) {
            case "en-US":
                return en_US;
            case "zh-CN":
                return zh_CN;
            default:
                return en_US;
        }
    }, [locale]);

    return (
        <IntlProvider locale={locale} messages={messages}>
            <SemiLocaleProvider locale={nextLocale}>
                {children}
            </SemiLocaleProvider>
        </IntlProvider>
    );
}