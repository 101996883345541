import { MobileAgentContext } from "contexts/mobileAgentContext";

type Props = {
    isMobileAgent: boolean
}

export default function MobileAgentProvider({ children, isMobileAgent }: React.PropsWithChildren<Props>): JSX.Element {
    return (
        <MobileAgentContext.Provider value={{ isMobileAgent }}>
            {children}
        </MobileAgentContext.Provider>
    );
}