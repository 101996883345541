import { request } from "apis/request";

export type ILoginResponse = {
    username: string
    token: string
}

export type IUserRole = "admin" | "user" | ""

export type ISupportedLanguageCode = "zh-CN" | "en-US"

export type IUserInfo = {
    countryCode: string
    createdAt: string
    email: string
    role: IUserRole
    lastLoginAt: string
    userID: number
    userName: string,
    practiceMode: boolean,
    languageCode: ISupportedLanguageCode,
}

export type IUserToken = {
    userName: string
    token: string
    countryCode: string
}

export function userLogin(data: FormData) {
    return request.post<IUserToken>('/user/login', data)
}

export function userLogout() {
    return request.get('/user/logout')
}

export function userSignup(data: FormData) {
    return request.post('/user/signup', data)
}

export function getUserSelfInfo(cookies?: string) {
    if (cookies != undefined)
        return request.get<IUserInfo>('/user/info', {
            headers: {
                Cookie: cookies
            }
        })
    return request.get<IUserInfo>('/user/info')
}

export function getSignupVerificationCode(data: FormData) {
    return request.post('/user/verify/email_signup', data)
}

export function getPasswordResetVerificationCode(data: FormData) {
    return request.post('/user/verify/password_reset', data)
}

export function getEmailChangeVerificationCode(data: FormData) {
    return request.post('/user/verify/email_change', data)
}

export function getPasswordResetToken(data: FormData) {
    return request.post('/user/token/password_reset', data)
}

export function resetPassword(data: FormData) {
    return request.post('/user/account/password_reset', data)
}

export function changeEmail(data: FormData) {
    return request.post('/user/account/email_change', data)
}

export function changePracticeMode(mode: boolean) {
    let data = new FormData()
    data.append("practice_mode", mode ? "true" : "false")
    return request.patch('/user/info/change_practice_mode', data)
}

export function changeLanguage(code: ISupportedLanguageCode) {
    let data = new FormData()
    data.append("languace_code", code)
    return request.patch('/user/info/change_language', data)
}