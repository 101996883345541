import { useCallback, useEffect, useState } from 'react'

import { AppContext, AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Head from 'next/head'

import UserProvider from 'providers/userProvider'
import LocaleProvider from 'providers/localeProvider'
import MobileAgentProvider from 'providers/mobileAgentProvider'
import MultiProvider from 'components/multiProvider'
import ProgressBar from 'components/progressBar'
import { getUserSelfInfo, IUserInfo } from "apis/user"
import 'styles/globals.css'
import "styles/Home.css"

// Baidu Statistics Tracking Codes
const getAnalyticsTag = () => {
  return {
    __html: `
    var _hmt = _hmt || [];
    (function() {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?034c5d8af9b582d6dfa606cd92856b0b";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();`,
  }
}

type IAppProps = {
  isMobileAgent: boolean
  userData: IUserInfo
}

function MyApp({ Component, isMobileAgent, userData }: AppProps & IAppProps) {
  const router = useRouter()
  const [progress, setProgress] = useState({
    show: false,
    percentage: 0
  })

  const handleRouteStart = useCallback(() => {
    setProgress({ show: true, percentage: 0 })
    setTimeout(() => {
      setProgress({ show: true, percentage: 40 })
    }, 0)
  }, [])

  const handleRouteComplete = useCallback(() => {
    setTimeout(() => {
      setProgress({ show: true, percentage: 100 })
      setTimeout(() => {
        setProgress({ show: false, percentage: 0 })
      }, 700)
    }, 500)

  }, [])

  useEffect(() => {
    router.events.on("routeChangeStart", handleRouteStart)
    router.events.on("routeChangeComplete", handleRouteComplete)

    return () => {
      router.events.off("routeChangeStart", handleRouteStart)
      router.events.off("routeChangeComplete", handleRouteComplete)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {process.env.NODE_ENV == "production" &&
        <Head>
          <script dangerouslySetInnerHTML={getAnalyticsTag()} />
        </Head>
      }

      <MultiProvider
        providers={[
          <UserProvider userData={userData} />,
          <LocaleProvider />,
          <MobileAgentProvider isMobileAgent={isMobileAgent} />,
        ]}
      >
        <ProgressBar {...progress} />
        <Component />
      </MultiProvider>
    </>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const userAgent = appContext.ctx.req ? appContext.ctx.req?.headers['user-agent'] : navigator.userAgent
  let userData = undefined as IUserInfo
  try {
    let res = await getUserSelfInfo(appContext.ctx.req.headers.cookie)
    userData = res.data
  } catch (error) {
    // Anonymous visitor
  }
  return {
    isMobileAgent: userAgent != undefined && /Mobi|Android|iPhone/i.test(userAgent),
    userData
  }
}

export default MyApp
