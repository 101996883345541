import { useCallback, useState, useEffect } from "react";

import { useRouter } from "next/router";

import { UserContext, } from "contexts/userContext";
import { getUserSelfInfo, IUserInfo, } from "apis/user"

const EBookAllowedCountries = ["CN"]

type Props = {
    userData: IUserInfo
}

export default function UserProvider({ children, userData }: React.PropsWithChildren<Props>): JSX.Element {
    const router = useRouter()
    const { pathname, asPath, query } = router

    const [state, setState] = useState({
        isLoggedIn: userData != undefined,
        userData,
        hasEBookAccess: userData != undefined ? EBookAllowedCountries.includes(userData.countryCode) : false,
    })

    useEffect(() => {
        if (userData?.languageCode == "zh-CN")
            router.push({ pathname, query }, asPath, { locale: 'zh-CN', scroll: false })
        else if (userData?.languageCode == "en-US")
            router.push({ pathname, query }, asPath, { locale: 'en-US', scroll: false })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData?.languageCode])

    const localLogIn = useCallback((data: IUserInfo) => {
        if (data.languageCode == "zh-CN")
            router.push({ pathname, query }, asPath, { locale: 'zh-CN', scroll: false })
        else if (data.languageCode == "en-US")
            router.push({ pathname, query }, asPath, { locale: 'en-US', scroll: false })

        setState(prev => ({
            ...prev,
            isLoggedIn: true,
            userData: data,
            hasEBookAccess: EBookAllowedCountries.includes(data.countryCode)
        }))
    }, [])

    const localLogOut = useCallback(() => {
        setState(prev => ({
            ...prev,
            isLoggedIn: false,
            userData: undefined,
            hasEBookAccess: false
        }))
    }, [])

    const refreshData = useCallback(() => {
        return new Promise<void>((resolve, reject) => {
            getUserSelfInfo().then(res => {
                setState(prev => ({
                    ...prev,
                    userData: res.data
                }))
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <UserContext.Provider
            value={{ ...state, localLogIn, localLogOut, refreshData }}
        >
            {children}
        </UserContext.Provider>
    );
}