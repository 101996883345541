export default function ProgressBar({ show, percentage }: { show: boolean, percentage: Number }) {
    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            height: 2,
            width: '100%',
            zIndex: Number.MAX_SAFE_INTEGER,
            display: show ? 'block' : 'none'
        }}>
            <div style={{
                height: '100%',
                background: 'var(--semi-color-primary)',
                width: percentage + "%",
                opacity: 1,
                transition: 'all 500ms ease 0s',
            }}>
            </div>

        </div>
    )
}