import { createContext } from "react";

import { IUserInfo, IUserToken } from 'apis/user';

export type IUserState = {
    isLoggedIn: boolean
    userData: IUserInfo
    hasEBookAccess: boolean
    localLogIn: (data: IUserInfo | IUserToken) => void
    localLogOut: () => void
    refreshData: () => Promise<void>
}

const UserContext = createContext<IUserState>({
    isLoggedIn: false,
    userData: undefined,
    hasEBookAccess: false,
    localLogIn: (data: IUserInfo | IUserToken) => { },
    localLogOut: () => { },
    refreshData: () => new Promise<void>((resolve) => { resolve() })
});
UserContext.displayName = 'UserContext';

export { UserContext }